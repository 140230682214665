import { css } from "@emotion/react";
import backgroundImg from "../../resources/img/nightCity.png";
import colors from "../../constants/colors";

export const containerProjects = css({
  display: "block",
  background: "#1a2a31",
  "@media (min-width: 820px)": {
    display: "flex",
    justifyContent: "space-between",
    height: "552px",
  },
});
export const leftContainerProject = css({
  backgroundImage: `url(${backgroundImg})`,
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  width: "100%",
  "@media (min-width: 820px)": {
    paddingBottom: 0,
    width: "50%",
    height: "100%",
  },
});
export const rightContainerProject = css({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  position: "relative",
  justifyContent: "center",
  "@media (min-width: 820px)": {
    padding: 0,
    paddingBottom: 0,
    width: "50%",
    height: "100%",
  },
});
export const containerArrows = css({
  backgroundColor: "var(--gray)",
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  top: "0%",
  width: 80,
  height: 80,
  bottom: 0,
  right: 80,
  svg: {
    width: "2rem",
    height: "2rem",
    color: colors.redBox,
  },
  "&:hover": {
    backgroundColor: colors.redBox,
    svg: {
      color: "var(--gray)",
    },
  },
  "@media (min-width: 820px)": {
    top: 0,
    left: 0,
  },
});
export const arrowRight = css({
  right: 0,
  "@media (min-width: 820px)": {
    top: 0,
    left: 80,
  },
});
export const carousel = css({
  display: "flex",
  width: "85%",
  paddingTop: "100px",
  ".MuiTypography-root": {
    fontFamily: "var(--fontSecondary)",
  },
});
export const card = css({
  background: "transparent",
  border: "none",
  boxShadow: "none",
});
export const cardTitleText = css({
  color: "var(--gray)",
  textAlign: "lefy",
});
export const cardText = css({
  color: "var(--gray)",
  textAlign: "justify",
});
