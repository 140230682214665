import { css } from "@emotion/react";
import colors from "../../constants/colors";
import backgroundImg from "../../resources/img/banner.png";

export const headerContainer = css({
  backgroundImage: `url(${backgroundImg})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  backgroundSize: "cover",
  height: "766px",
  width: "100%",
});
export const bannerContainer = css({
  display: "flex",
  flexWrap: "nowrap",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px",
  "@media (min-width: 820px)": {
    padding: "50px",
  },
});
export const bannerOpacity = css({
  backgroundColor: "#1e2f3890",
  height: "766px",
});
export const bannerLogo = css({
  color: colors.mainTitle,
  width: "450px",
  height: "50px",
  display: "flex",
});
export const nav = css({
  display: "none",
  "@media (min-width: 820px)": {
    display: "flex",
  },
});
export const textLinks = css({
  color: colors.mainTitle,
  fontSize: 20,
  marginLeft: 25,
  marginRight: 25,
});
export const mainTitle = css({
  color: colors.mainTitle,
  fontSize: 48,
  fontWeight: "100",
  textTransform: "none",
  marginLeft: "25px",
  "@media (min-width: 820px)": {
    marginLeft: "175px",
    marginTop: "80px",
  },
});
export const mainText = css({
  color: colors.mainTitle,
  fontSize: 20,
  fontWeight: "normal",
  fontStyle: "normal",
  textAlign: "justify",
  textTransform: "none",
  padding: "0 25px",
  "@media (min-width: 820px)": {
    paddingLeft: "175px",
    maxWidth: "60%",
    margin: 0,
  },
});
export const mainTextBold = css({
  color: colors.mainTitle,
  fontSize: 20,
  fontWeight: "bold",
  fontStyle: "normal",
  textAlign: "left",
  textTransform: "none",
  padding: "0 25px",
  "@media (min-width: 820px)": {
    paddingLeft: "175px",
    maxWidth: "50%",
    margin: 0,
  },
});
export const slideContainer = css({
  marginRight: "350px",
  marginTop: "80px",
  display: "none",
  "@media (min-width: 820px)": {
    paddingLeft: "175px",
    display: "flex",
  },
});
export const slide = css({
  color: colors.mainTitle,
  fontSize: 20,
  fontWeight: "normal",
  fontStyle: "normal",
  textAlign: "right",
  textTransform: "none",
});

export const button = css({
  position: "fixed",
  top: "80%",
  right: 50,
  zIndex: 6,
  span: {
    fontSize: 16,
    color: "var(--white)",
  },
  button: {
    marginLeft: 10,
    backgroundColor: "var(--gray)",
  },
});
