import React from "react";
import ReactDOM from "react-dom";
import { CssBaseline } from "@material-ui/core";
import App from "./App";

ReactDOM.render(
  <>
    <CssBaseline />
    <App />
  </>,
  document.querySelector("#root")
);
