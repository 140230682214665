import { css } from "@emotion/react";
import colors from "../../constants/colors";
import backgroundImg from "../../resources/img/LawyerBackground.jpg";

export const bigBoxBodyContainer = css({
  display: "flex",
  flexDirection: "column",
  borderStyle: "solid",
  borderWidth: "0.5px",
});
export const upperContainer = css({
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  "@media (min-width: 820px)": {
    display: "flex",
    height: "650px",
  },
});
export const leftUpperBox = css({
  position: "relative",
  display: "flex",
  height: "100%",
  width: "100%",
  "@media (min-width: 1024px)": {
    width: "60%",
  },
});
export const rightUpperBox = css({
  flexDirection: "column",
  display: "flex",
  justifyContent: "center",
  height: "100%",
  width: "100%",
  ".row-title": {
    padding: "6px 25px!important",
  },
  "@media (min-width: 820px)": {
    width: "40%",
    display: "flex",
  },
});
export const trazadoContainer = css({
  width: "100%",
  height: "100%",
  position: "absolute",
});
export const exp = css({
  color: colors.x360,
  fontSize: 35,
  padding: "25px",
  fontWeight: "normal",
  fontStyle: "normal",
  textTransform: "none",
  backgroundColor: "transparent",
  zIndex: 1,
  "@media (min-width: 820px)": {
    justifyContent: "center",
    alignSelf: "center",
    marginLeft: "100px",
    marginRight: "100px",
    fontSize: 48,
  },
});
export const profileContainer = css({
  backgroundColor: "var(--white)",
  zIndex: 2,
});
export const downContainer = css({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  width: "100%",
  "@media (min-width: 820px)": {
    height: "920px",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  "@media (min-width: 1080px)": {
    height: "720px",
    justifyContent: "space-between",
    flexDirection: "row",
  },
});
export const line = css({
  display: "inline-block",
  verticalAlign: "text-top",
  minWidth: "40px",
  marginRight: "20px",
  border: `2px solid ${colors.redBox}`,
  overflow: "hidden",
});
export const arrowSize = css({
  marginRight: "50px",
  width: "8px",
  height: "16px",
});
export const texts = css({
  display: "flex",
  alignItems: "center",
  color: colors.x360,
  fontSize: 20,
  fontWeight: "normal",
  fontStyle: "normal",
  textTransform: "none",
  letterSpacing: "2px",
  marginBottom: 20,
});
export const leftBottomBox = css({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  height: "100%",
  width: "100%",
  "@media (min-width: 820px)": {
    width: "50%",
  },
});
export const leftBottomTextContainer = css({
  paddingTop: "50px",
  paddingBottom: "50px",
  padding: "50px 25px 50px 25px",
  textAlign: "left",
});
export const sav = css({
  whiteSpace: "wrap",
  textAlign: "left",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: 28,
  textTransform: "none",
  color: colors.orange,
});
export const savInfo = css({
  textAlign: "justify",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: 20,
  marginBottom: "25px",
  "@media (min-width: 1200px)": {
    fontSize: 20,
    marginBottom: "25px",
  },
  textTransform: "none",
  color: colors.savInfo,
});
export const rightBottomBox = css({
  backgroundImage: `url(${backgroundImg})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top center",
  backgroundSize: "cover",
  paddingBottom: 300,
  width: "100%",
  zIndex: 1,
  "@media (min-width: 820px)": {
    width: "50%",
    height: "100%",
    paddingBottom: 0,
  },
});

export const experienceMovil = css({
  display: "block",
  paddingLeft: 0,
  "@media (min-width: 820px)": {
    display: "none",
  },
});
