import React from "react";
import { Global, css } from "@emotion/react";

const Styles = () => (
  <Global
    styles={css`
      @font-face {
        font-family: Viola;
        src: url("fonts/Viola.ttf");
      }
      @font-face {
        font-family: "Nelphim-Regular";
        src: url("fonts/Nephilm.otf");
      }
      :root {
        --black: #292f35;
        --white: #ffffff;
        --gray: #d6d6d6;
        --purple: #8940fa;
        --blue: #41d6b9;
        --green: #228b22;
        --fontPrimary: Viola;
        --fontSecondary: "Nelphim-Regular";
      }
      html {
        box-sizing: border-box;
        color: var(--black);
        background-color: #293d48;
        scroll-behavior: smooth;
        @media (min-width: 1700px) {
          padding-right: 12.5%;
          padding-left: 12.5%;
        }
      }
      body {
        text-rendering: optimizeLegibility;
        line-height: 1.5;
        font-family: var(--fontSecondary) !important;
        margin: auto;
        overflow-x: hidden;
        scroll-behavior: smooth;
      }
      h1 h2 h3 h4 {
        font-family: var(--fontSecondary) !important;
        font-size: 30;
        font-weight: bold;
        text-transform: uppercase;
      }
      ul {
        list-style: none;
        padding: 0;
      }
      a {
        font-family: var(--fontSecondary);
        text-decoration: none;
        color: var(--black);
      }
      .MuiContainer-root {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
      .styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c {
        border-bottom: none;
      }
      .icon-wrapper {
        left: 13;
      }
      .row-title {
        padding: 6px 0 6px 50px !important;
      }
    `}
  />
);

export default Styles;
