import { css } from "@emotion/react";
import colors from "../../constants/colors";

export const containerFooter = css({
  height: "initial",
  backgroundColor: colors.x360,
  padding: "80px 20px",
  color: "var(--white)",
  "@media (min-width: 820px)": {
    height: 400,
    padding: "80px 150px",
  },
});

export const diver = css({
  backgroundColor: "var(--gray)",
  marginBottom: 40,
});

export const logoFooter = css({
  width: "100%",
  "@media (min-width: 820px)": {
    width: 300,
    height: 150,
  },
});

export const contentFooter = css({
  display: "flex",
  justifyContent: "space-evenly",
  flexDirection: "column-reverse",
  "@media (min-width: 820px)": {
    flexDirection: "row",
  },
});

export const titleFooter = css({
  fontSize: 24,
  marginTop: 30,
});

export const items = css({
  display: "flex",
  flexDirection: "column",
  marginBottom: 20,
  "span, a": {
    marginBottom: 10,
    fontSize: 16,
    color: "var(--white)",
  },
});
