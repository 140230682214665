import React from "react";
import * as styles from "./style";

const BlueBox = () => {
  return (
    <section id="servicios" css={styles.blueBoxContainer}>
      <h1 css={styles.blueBoxTitle}>Servicios</h1>
      <p css={styles.blueBoxText}>
        ¿Cómo puedo ayudarte?
        <br />
        <br />
        Mi enfoque va desde la redacción impecable y especializada de un
        contrato, hasta la orientación para determinar la vía idónea para la
        ejecución de tu proyecto. <br />
        <br />A continuación, encontrarás algunos de los servicios en los que
        destaco por mi experiencia:
      </p>
    </section>
  );
};

export default BlueBox;
