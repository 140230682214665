import { css } from "@emotion/react";
import colors from "../../constants/colors";
import contactImage from "../../resources/img/contactImage.png";

export const contactContainer = css({
  display: "flex",
  flexDirection: "column",
  borderStyle: "solid",
  borderWidth: "0.5px",
  borderColor: colors.redBoxBorder,
  backgroundImage: `url('${contactImage}')`,
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
});
export const contactImageOpacity = css({
  backgroundColor: "#1e2f3899",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  padding: 25,
  height: "100%",
  "@media (min-width: 820px)": {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 150px",
  },
});
export const contactTitle = css({
  color: colors.redBoxTitle,
  fontSize: 45,
  width: "100%",
  fontWeight: "400",
  fontStyle: "normal",
  lineHeight: "normal",
  textAlign: "left",
  textTransform: "none",
  marginBottom: 50,
  marginTop: 0,
  "@media (min-width: 820px)": {
    marginBottom: 0,
    width: "50%",
  },
});
export const contactText = css({
  color: colors.redBoxTitle,
  fontSize: 20,
  textAlign: "justify",
  fontWeight: "normal",
  fontStyle: "normal",
  textTransform: "none",
  width: "100%",
  margin: 0,
  "@media (min-width: 820px)": {
    paddingTop: "15px",
    paddingBottom: "15px",
    width: "50%",
  },
});
export const contactForm = css({
  height: "initial",
  width: "100%",
  "@media (min-width: 820px)": {
    height: 500,
  },
});
export const sectionForm = css({
  width: "80%",
  margin: "0 auto",
  padding: "30px 0",
  display: "flex",
  flexDirection: "column",
  "@media (min-width: 820px)": {
    marginBottom: 20,
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
export const textFieldSection1 = css({
  width: "100%",
  marginBottom: 20,
  "@media (min-width: 820px)": {
    width: "30%",
  },
});
export const textArea = css({
  width: "100%",
  marginTop: 20,
  "@media (min-width: 820px)": {
    width: "65%",
  },
});
export const button = css({
  backgroundColor: colors.x360,
  color: "var(--white)",
  width: "100%",
  height: 50,
  "@media (min-width: 820px)": {
    width: "30%",
  },
});
export const mainText = css({
  color: colors.mainTitle,
  fontSize: 20,
  fontWeight: "normal",
  fontStyle: "normal",
  textAlign: "left",
  textTransform: "none",
  padding: "0 25px",
  "@media (min-width: 820px)": {
    paddingLeft: "175px",
    maxWidth: "50%",
    margin: 0,
  },
});
