/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { useFormik } from "formik";
import { Button, TextField } from "@material-ui/core";
import Swal from "sweetalert2";
import emailjs from "@emailjs/browser";
import * as yup from "yup";
import * as styles from "./style";

const validationSchema = yup.object({
  from_name: yup
    .string("El nombre es obligatorio")
    .required("El nombre es obligatorio"),
  from_lastname: yup.string(),
  phone: yup.number("Este campo solo admite números"),
  email: yup
    .string("El e-mail es obligatorio")
    .required("El e-mail es obligatorio"),
  message: yup
    .string("Escríbanos su consulta")
    .required("La consulta es obligatoria"),
});

const sendEmail = (e) => {
  e.preventDefault();
  emailjs
    .sendForm(
      "service_t6nf675",
      "template_b08sb8d",
      e.target,
      "3x-JRsTUzGQw75f6V"
    )
    .then(
      () => {
        Swal.fire(
          "Mensaje enviado!",
          "Te responderemos lo más pronto posible",
          "success"
        );
      },
      () => {
        Swal.fire("Opps!", "Algo salió mal", "error");
      }
    );
};

const Contact = () => {
  const formik = useFormik({
    initialValues: {
      from_name: "",
      from_lastname: "",
      phone: "",
      email: "",
      message: "",
    },
    validationSchema,
  });
  const inputsForm = [
    { id: "from_name", label: "Nombre", name: "from_name" },
    { id: "from_lastname", label: "Apellido", name: "from_lastname" },
    { id: "phone", label: "Celular", name: "phone" },
  ];
  return (
    <>
      <div css={styles.contactContainer}>
        <div css={styles.contactImageOpacity}>
          <h1 css={styles.contactTitle}>Contacto</h1>
          <p css={styles.contactText}>
            ¿Quieres experimentar una nueva forma de contratar servicios
            legales?
            <br />
            <br />
            Diligencia este formulario y ponte en contacto conmigo. Te
            responderé en el menor tiempo posible.
            <br />
            <br />
            Gracias por tu interés en mis servicios. Desde este momento tu
            consulta es mi prioridad.
          </p>
        </div>
      </div>
      <div css={styles.contactForm}>
        <form id="contacto" onSubmit={sendEmail}>
          <div css={styles.sectionForm}>
            {inputsForm.map((inputForm) => (
              <TextField
                css={styles.textFieldSection1}
                key={inputForm.id}
                id={inputForm.id}
                label={inputForm.label}
                defaultValue={formik.values[inputForm.id]}
                onChange={formik.handleChange}
                error={
                  formik.touched[inputForm.id] &&
                  Boolean(formik.errors[inputForm.id])
                }
                name={inputForm.name}
                helperText={
                  formik.touched[inputForm.id] && formik.errors[inputForm.id]
                }
              />
            ))}
          </div>
          <div css={styles.sectionForm}>
            <TextField
              id="email"
              label="E-mail"
              css={styles.textFieldSection1}
              defaultValue={formik.values.email}
              onChange={formik.handleChange}
              name="email"
              error={formik.touched.email && Boolean(formik.errors.email)}
            />
            <TextField
              id="message"
              label="Mensaje"
              css={styles.textArea}
              multiline
              rows={6}
              value={formik.values.message}
              onChange={formik.handleChange}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
              variant="outlined"
              name="message"
            />
          </div>
          <div
            css={[styles.sectionForm, { justifyContent: "flex-end!important" }]}
          >
            <Button
              css={styles.button}
              variant="contained"
              type="submit"
              disableElevation
            >
              Enviar
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Contact;
