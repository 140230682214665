import { css } from "@emotion/react";
import colors from "../../constants/colors";

export const blueBoxContainer = css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderStyle: "solid",
  borderWidth: "0.5px",
  backgroundColor: colors.logoBlue,
  borderColor: colors.redBoxBorder,
  padding: "0 25px",
  height: "466px",
  "@media (min-width: 820px)": {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
export const blueBoxTitle = css({
  color: colors.redBoxTitle,
  fontSize: 45,
  width: "100%",
  fontWeight: "400",
  fontStyle: "normal",
  lineHeight: "normal",
  textAlign: "left",
  textTransform: "none",
  marginBottom: 50,
  marginTop: 0,
  "@media (min-width: 820px)": {
    paddingLeft: 100,
    marginBottom: 0,
    width: "40%",
  },
});
export const blueBoxText = css({
  color: colors.redBoxTitle,
  fontSize: 20,
  textAlign: "justify",
  fontWeight: "normal",
  fontStyle: "normal",
  textTransform: "none",
  width: "100%",
  margin: 0,
  "@media (min-width: 820px)": {
    width: "50%",
  },
});
