import React from "react";
import * as styles from "./style";

const RedBox = () => {
  return (
    <section css={styles.redBoxContainer}>
      <h1 css={styles.redBoxTitle}>Enfoque</h1>
      <p css={styles.redBoxText}>
        Con mi experiencia y alto nivel de compromiso, acompaño a personas
        naturales y jurídicas a navegar a través de las distintas opciones
        legales para que lleven a cabo sus proyectos de forma confiable y
        segura. <br />
        <br />
        Soy experto en estructuración de proyectos inmobiliarios y los contratos
        referentes a esta industria, tales como: estudio de títulos,
        arrendamientos, fiducias, compraventas, distintos vehículos de inversión
        y aquellos relacionados con las operaciones transaccionales del derecho
        inmobiliario.
      </p>
    </section>
  );
};

export default RedBox;
