import { css } from "@emotion/react";
import colors from "../../constants/colors";

export const redBoxContainer = css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderStyle: "solid",
  borderWidth: "0.5px",
  backgroundColor: colors.redBox,
  borderColor: colors.redBoxBorder,
  padding: "25px",

  "@media (min-width: 820px)": {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 150px",
  },
});
export const redBoxTitle = css({
  color: colors.redBoxTitle,
  fontSize: 45,
  width: "100%",
  fontWeight: "400",
  fontStyle: "normal",
  lineHeight: "normal",
  textAlign: "left",
  textTransform: "none",
  marginBottom: 50,
  marginTop: 0,
  "@media (min-width: 820px)": {
    marginBottom: 0,
    width: "40%",
  },
});
export const redBoxText = css({
  color: colors.redBoxTitle,
  fontSize: 20,
  textAlign: "justify",
  fontWeight: "normal",
  fontStyle: "normal",
  textTransform: "none",
  width: "100%",
  margin: 0,
  "@media (min-width: 820px)": {
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: 100,
    width: "60%",
  },
});
