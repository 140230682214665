import { Divider } from "@material-ui/core";
import React from "react";
import * as styles from "./style";
import { ReactComponent as Sav } from "../../resources/svg/SAVwhiteLogo.svg";

const Footer = () => {
  return (
    <footer css={styles.containerFooter}>
      <Divider css={styles.diver} />
      <div css={styles.contentFooter}>
        <Sav css={styles.logoFooter} />
        <div>
          <h3 css={styles.titleFooter}>Contacto</h3>
          <div css={styles.items}>
            <span>Teléfono</span>
            <span>+57 (301) 622 4334</span>
          </div>
          <div css={styles.items}>
            <span>E-mail</span>
            <span>santiago.arango@sav-legal.com</span>
          </div>
        </div>
        <div>
          <h3 css={styles.titleFooter}>Menú</h3>
          <div css={styles.items}>
            <a href="/">Inicio</a>
            <a href="#santiago">Santiago</a>
            <a href="#servicios">Servicios</a>
            <a href="#contacto">Contacto</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
