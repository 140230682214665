import React from "react";
import Styles from "../Styles";
import Header from "../Header";
import Footer from "../Footer";
import RedBox from "../RedBox";
import BigBoxBody from "../BigBoxBody";
import Projects from "../Projects";
import Contact from "../Contact";
import BlueBox from "../BlueBox";

const Layout = () => (
  <>
    <Styles />
    <Header />
    <RedBox />
    <BigBoxBody />
    <BlueBox />
    <Projects />
    <Contact />
    <Footer />
  </>
);

export default Layout;
