import React from "react";
import Faq from "react-faq-component";
import * as styles from "./style";
import { ReactComponent as Trazado } from "../../resources/svg/TrazadoBackground.svg";
import colors from "../../constants/colors";

const BigBoxBody = () => {
  const data = {
    rows: [
      {
        title: "SAV-Legal: 2020 – Actualidad",
        content:
          "En mi labor como asesor independiente, he participado en la estructuración de proyectos inmobiliarios, brindando también una constante asesoría en gestión predial de diversos proyectos de energía renovable.",
      },
      {
        title: "Gómez-Pinzón Abogados: 2017 – 2020",
        content:
          "Durante tres (3) años fui parte del equipo de Derecho Inmobiliario y Urbanístico de GPA, a través del cual participé en la estructuración de diversos proyectos inmobiliarios, incluyendo urbanizaciones, centros comerciales, hoteles y vivienda, en distintas partes del país.",
      },
      {
        title: "Advocat Servicios Legales: 2016 – 2017",
        content:
          "Brindé servicios jurídicos especializados en el área de Derecho Inmobiliario, incluyendo el manejo de diversos tipos de contratos, tanto en inglés como en español.",
      },
      {
        title: "Lloreda Camacho: 2014",
        content:
          "Presté apoyo como Practicante Jurídico enfocado en distintas áreas del Derecho, destacando asuntos corporativos, laborales y migratorios.",
      },
    ],
  };
  const config = {
    animate: true,
  };
  const style = {
    bgColor: "transparent",
    rowTitleColor: "black",
    rowContentTextSize: "16px",
    rowContentPaddingBottom: "10px",
    rowContentPaddingLeft: "50px",
    rowContentPaddingRight: "10px",
    arrowColor: colors.redBox,
  };
  return (
    <section css={styles.bigBoxBodyContainer}>
      <div css={styles.upperContainer}>
        <div css={styles.leftUpperBox}>
          <h1 css={styles.exp}>
            Trayectoria
            <br />
            <hr css={styles.line} />
            <p css={styles.savInfo}>
              Durante mi trayectoria laboral me he mantenido a la vanguardia de
              la legislación y la jurisprudencia, con el propósito de adaptarme
              rápidamente a las necesidades de mis clientes.
              <br />
              <br />
              Trabajar en grandes firmas de abogados, como las que te presento a
              continuación, me ha llevado a adquirir una perspectiva más amplia
              de lo que significa ser un asesor jurídico integral y tener una
              visión periférica en los proyectos de inversión:
            </p>
          </h1>
          <div css={styles.trazadoContainer}>
            <Trazado />
          </div>
        </div>
        <div css={styles.rightUpperBox}>
          <Faq data={data} styles={style} config={config} />
        </div>
      </div>
      <section css={styles.profileContainer}>
        <div id="santiago" css={styles.downContainer}>
          <div css={styles.leftBottomBox}>
            <div css={styles.leftBottomTextContainer}>
              <h1 css={styles.sav}>Valores y Credenciales</h1>
              <p css={styles.savInfo}>
                Soy un abogado apasionado por el sector inmobiliario. Tengo una
                mentalidad disruptiva y la misión concreta de mostrarte una
                nueva faceta de la asesoría jurídica. Me actualizo
                constantemente en las fluctuaciones del mercado y los
                potenciales de inversión.
                <br />
                <br /> Creo firmemente en el poder de hacer las cosas con
                conocimiento, seriedad y transparencia. Me comprometo con tu
                proyecto y te brindo un acompañamiento que busca obtener los
                resultados más favorables, basados en una metodología de
                investigación y análisis detallado.
                <br />
                <br /> Mi labor es evaluar la viabilidad de los proyectos y
                orientarte en la determinación de la figura jurídica más
                adecuada para su correcto desarrollo.
                <br />
                <br />
                <li>
                  Estudié Derecho y Ciencias Políticas en la Universidad de La
                  Sabana.
                </li>
                <li>Certificado en inglés legal avanzado.</li>
              </p>
            </div>
          </div>
          <div css={styles.rightBottomBox} />
        </div>
      </section>
    </section>
  );
};

export default BigBoxBody;
