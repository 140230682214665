import React from "react";
import { Fab } from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import * as styles from "./style";
import { ReactComponent as Sav } from "../../resources/svg/SAVHeader.svg";

const Header = () => {
  return (
    <div css={styles.headerContainer}>
      <div css={styles.bannerOpacity}>
        <div css={styles.bannerContainer}>
          <div css={styles.bannerLogo}>
            <Sav />
          </div>
          <div css={styles.nav}>
            <a css={styles.textLinks} href="/" onClick={null}>
              Inicio
            </a>
            <a css={styles.textLinks} href="#santiago" onClick={null}>
              Santiago
            </a>
            <a css={styles.textLinks} href="#servicios" onClick={null}>
              Servicios
            </a>
            <a css={styles.textLinks} href="#contacto" onClick={null}>
              Contacto
            </a>
          </div>
        </div>
        <h1 css={styles.mainTitle}>Santiago Arango Valderrama</h1>
        <h2 css={styles.mainText}>
          <br />
          Abogado bilingüe, especialista en Derecho Inmobiliario.
        </h2>
        <br />
        <h2 css={styles.mainTextBold}>Tu consulta es mi prioridad.</h2>
        <br />
        <h2 css={styles.mainText}>
          Mi propósito es mostrarte una nueva forma de contratar servicios
          legales. Estoy aquí para brindarte un acompañamiento personalizado,
          profesional y a la medida de tus necesidades.
        </h2>
      </div>
      <div css={styles.button}>
        <Fab
          aria-label="add"
          variant="extended"
          onClick={() => window.scrollTo(0, 0)}
        >
          <KeyboardArrowUpIcon />
          <span>Subir</span>
        </Fab>
      </div>
    </div>
  );
};

export default Header;
