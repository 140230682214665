export default {
  white: "#fff",
  red: "#FF0000",
  black: "#000000",
  logoBlue: "#293d48",
  logoRed: "#a6242f",
  mainText: "#1d2c49",
  mainTitle: "#F5F5F5",
  redBoxTitle: "#d5cbc9",
  redBox: "#931423",
  redBoxBorder: "#707070",
  x360: "#1e2f38",
  blur: "rgba(30,47,56,1)",
  orange: "#A96954",
  savInfo: "#807C7A",
};
