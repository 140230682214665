/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { Card, CardContent, Typography } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import * as styles from "./style";

SwiperCore.use([Navigation]);

const Projects = () => {
  const [swiper, setSwiper] = useState();

  const clients = [
    {
      id: 1,
      nameClient: "Asesoría Contractual",
      descriptionJob:
        "Este servicio consiste en la revisión, negociación y/o elaboración de todo tipo de contratos, pero en especial aquellos relacionados con el sector inmobiliario:",
    },
    {
      id: 2,
      nameClient: "Asesoría Contractual",
      descriptionJob: [
        <table>
          <thead>
            <tr>
              <td key={1}>✓ Estudio de Títulos</td>
              <td key={6}>✓ Contratos de Obra</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td key={2}>✓ Promesas</td>
              <td key={7}>✓ Cuentas en Participación</td>
            </tr>
            <tr>
              <td key={3}>✓ Arrendamientos</td>
              <td key={8}>✓ Colaboración Empresarial</td>
            </tr>
            <tr>
              <td key={4}>✓ Fiducia Mercantil</td>
              <td key={9}>✓ Compraventas</td>
            </tr>
            <tr>
              <td key={5}>✓ Encargos Fiduciarios </td>
              <td key={10}>✓ Otros</td>
            </tr>
          </tbody>
        </table>,
      ],
    },
    {
      id: 3,
      nameClient: "Estructuración de Proyectos Inmobiliarios",
      descriptionJob:
        "Mediante este servicio te acompaño en la constitución, negociación y cierre del vehículo de inversión elegido para tu proyecto, con el respaldo de distintos profesionales con los cuales he generado una alianza para poder brindar una asesoría integral.",
    },
    {
      id: 4,
      nameClient: "Asuntos Corporativos o Societarios",
      descriptionJob:
        "Te acompaño en la constitución, disolución y/o liquidación de sociedades, redacción de estatutos, actas y demás trámites internos del derecho corporativo.",
    },
  ];

  return (
    <div css={styles.containerProjects}>
      <div css={styles.leftContainerProject} />
      <div css={styles.rightContainerProject}>
        <div
          className="arrowPrev"
          css={styles.containerArrows}
          onClick={() => swiper?.slidePrev?.()}
        >
          <ChevronLeftIcon />
        </div>
        <div
          className="arrowNext"
          css={[styles.containerArrows, styles.arrowRight]}
          onClick={() => swiper?.slideNext?.()}
        >
          <ChevronRightIcon />
        </div>
        <Swiper
          css={styles.carousel}
          spaceBetween={50}
          slidesPerView={1}
          onInit={(event) => setSwiper(event)}
          navigation={{
            nextEl: "arrowNext",
            prevEl: "arrowPrev",
          }}
          loop
        >
          {clients.map((client) => (
            <SwiperSlide key={client.id}>
              <Card css={styles.card}>
                <CardContent>
                  <Typography
                    css={styles.cardTitleText}
                    variant="h4"
                    component="h2"
                  >
                    {client.nameClient}
                  </Typography>
                  <br />
                  <br />
                  <Typography
                    css={styles.cardText}
                    variant="body1"
                    component="p"
                  >
                    {client.descriptionJob}
                  </Typography>
                </CardContent>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Projects;
